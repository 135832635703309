/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/parsleyjs@2.9.2/dist/parsley.min.js
 * - /npm/choices.js@9.0.1/public/assets/scripts/choices.min.js
 * - /npm/corejs-typeahead@1.3.1/dist/bloodhound.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
